// Generated by Framer (f5febb9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["N1AZ50ZTh", "AAYVLeW4l", "QQR83q0VD", "zV3EV28Eq", "Mow6PNMu6", "toCVwUdoy"];

const serializationHash = "framer-uMSJJ"

const variantClassNames = {AAYVLeW4l: "framer-v-1i8gsoe", Mow6PNMu6: "framer-v-15d6zsi", N1AZ50ZTh: "framer-v-1axrcl0", QQR83q0VD: "framer-v-da9ywj", toCVwUdoy: "framer-v-mju7c", zV3EV28Eq: "framer-v-1xazmuz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.1, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "N1AZ50ZTh", "Variant 2": "AAYVLeW4l", "Variant 3": "QQR83q0VD", "Variant 4": "zV3EV28Eq", "Variant 5": "Mow6PNMu6", "Variant 6": "toCVwUdoy"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "N1AZ50ZTh"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "N1AZ50ZTh", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear19q293j = activeVariantCallback(async (...args) => {
await delay(() => setVariant("AAYVLeW4l"), 600)
})

const onAppear1ei7y09 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("QQR83q0VD"), 100)
})

const onAppearosggry = activeVariantCallback(async (...args) => {
await delay(() => setVariant("N1AZ50ZTh"), 600)
})

const onAppear1hdw1hf = activeVariantCallback(async (...args) => {
await delay(() => setVariant("Mow6PNMu6"), 600)
})

const onAppear1fes1jh = activeVariantCallback(async (...args) => {
await delay(() => setVariant("toCVwUdoy"), 100)
})

const onAppearohf30k = activeVariantCallback(async (...args) => {
await delay(() => setVariant("zV3EV28Eq"), 600)
})

useOnVariantChange(baseVariant, {AAYVLeW4l: onAppear1ei7y09, default: onAppear19q293j, Mow6PNMu6: onAppear1fes1jh, QQR83q0VD: onAppearosggry, toCVwUdoy: onAppearohf30k, zV3EV28Eq: onAppear1hdw1hf})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1axrcl0", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"N1AZ50ZTh"} ref={ref ?? ref1} style={{backgroundColor: "rgb(192, 29, 36)", borderBottomLeftRadius: 1000, borderBottomRightRadius: 1000, borderTopLeftRadius: 1000, borderTopRightRadius: 1000, ...style}} variants={{Mow6PNMu6: {backgroundColor: "rgb(66, 163, 58)"}, toCVwUdoy: {backgroundColor: "rgb(66, 163, 58)"}, zV3EV28Eq: {backgroundColor: "rgb(66, 163, 58)"}}} {...addPropertyOverrides({AAYVLeW4l: {"data-framer-name": "Variant 2"}, Mow6PNMu6: {"data-framer-name": "Variant 5"}, QQR83q0VD: {"data-framer-name": "Variant 3"}, toCVwUdoy: {"data-framer-name": "Variant 6"}, zV3EV28Eq: {"data-framer-name": "Variant 4"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-uMSJJ.framer-fxugkr, .framer-uMSJJ .framer-fxugkr { display: block; }", ".framer-uMSJJ.framer-1axrcl0 { height: 16px; overflow: visible; position: relative; width: 16px; }", ".framer-uMSJJ.framer-v-1i8gsoe.framer-1axrcl0, .framer-uMSJJ.framer-v-15d6zsi.framer-1axrcl0 { height: 12px; width: 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"AAYVLeW4l":{"layout":["fixed","fixed"]},"QQR83q0VD":{"layout":["fixed","fixed"]},"zV3EV28Eq":{"layout":["fixed","fixed"]},"Mow6PNMu6":{"layout":["fixed","fixed"]},"toCVwUdoy":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramernVOf60knr: React.ComponentType<Props> = withCSS(Component, css, "framer-uMSJJ") as typeof Component;
export default FramernVOf60knr;

FramernVOf60knr.displayName = "flashing dot";

FramernVOf60knr.defaultProps = {height: 16, width: 16};

addPropertyControls(FramernVOf60knr, {variant: {options: ["N1AZ50ZTh", "AAYVLeW4l", "QQR83q0VD", "zV3EV28Eq", "Mow6PNMu6", "toCVwUdoy"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5", "Variant 6"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramernVOf60knr, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})